.pagination-wrapper {
    display: flex;
    width: 70%;
    grid-gap: 16px;
    gap: 16px;
    margin-top: 0.5rem;
    background-color: #ffffff;
    padding: 20px 0px;
}
.pagination {
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0px 5%;
    list-style: none;
    width: 100%;
    display: flex;
}

.page-link {
    width: 120px;
    height: 30px;
    border: none;
    display: flex;
    background: none;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
}
.page-link .page-link {
    color: #025f4c !important;
}
.dataTable_controls .page-link[disabled] {
    color: rgb(160, 174, 192);
}
.page-link {
    width: 120px;
    height: 30px;
}
.page-item {
    display: flex;
    float: left;
    margin: 0px 5px;
}

.rdt_TableHeadRow {
    background: #025f4c !important;
    color: #ffffff;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-transform: capitalize;
    border-right: 1px solid #e6e6e6 !important;
}
.rdt_TableCell {
    padding: 15px !important;
    border-right: 1px solid #e6e6e6 !important;
    border-bottom: 2px solid #e6e6e6 !important;
}
.rdt_TableCell div {
    text-overflow: ellipsis;
    word-break: break-word !important;
    white-space: normal !important;
}

.rdt_TableRow:hover {
    cursor: pointer;
    background-color: #e8f5ef !important;
}
.rdt_TableRow {
    min-height: 45px !important;
}
.rdt_TableBody {
    padding-bottom: 30px;
}
.prevArrow {
    transform: rotate(90deg);
    margin-right: 5px;
    width: 18px;
}
.nextArrow {
    transform: rotate(270deg);
    margin-left: 5px;
    width: 18px;
}